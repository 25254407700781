import React from "react"
import styled from "styled-components"
import {useStaticQuery, graphql} from "gatsby"
import SEO from "../components/seo"
import SVG from "../components/svg"
import Layout from "../components/layout"
import Button from "../components/button"
import ButtonColorNav from "../components/bottom-color-nav"

const ContentStyled = styled.div `
  margin: 0 auto;
  padding: 0px 0;
  top: 50px;

  .topLine {
    margin: 0;
  }

  .posterText {
    top: 130px;
    left: 50px;
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column;
    margin: 50px auto;
    text-align: center;
    z-index: 1;

    p {
      text-align: left;
      max-width: 300px;
      color: ${p => p.color || "white"};
    }

    @media (max-width: 768px) {
      left: 20px;
    }
  }

  .bgImg {
    position: relative;
    z-index: 0;
    overflow: hidden;
    min-height: 1050px;
    img {
      position: absolute;
      left: 0;
      width: auto;
    }

    @media (max-width: 1441px) {
      min-height: 600px;
      width: 1600px;
      img {
        right: auto;
        left: 0;
        height: 600px;
      }
    }
  }

  h1 {
    width: 100%;
    font-size: 9.2em;
    margin: 0 0 10px -10px;
    line-height: 0.9em;
    font-family: "interstate-compressed";
    color: ${p => p.headline_color || p.theme.DarkBlue};
  }

  p {
    max-width: 450px;
    color: ${p => p.statement_color || p.theme.DarkBlue};
    a {
      color: ${p => p.statement_color || p.theme.DarkBlue};
    }
  }

  .center {
    text-align: center;
    padding: 10px 0 60px 0;
  }
`

const Content = ({acf}) => {
  const {
    button_label,
    headline,
    headline_color,
    top_line,
    top_line_color,
    statement,
    statement_color,
    wave_color
  } = acf
  return (<ContentStyled top_line_color={top_line_color} headline_color={headline_color} statement_color={statement_color}>
    <div className="bgImg">
      <img alt="Not Found 404" src={acf.background_image.localFile.childImageSharp.fluid.src}/>
    </div>
    <div className="posterText">
      <p className="topLine" dangerouslySetInnerHTML={{
          __html: top_line
        }}/>
      <h1 dangerouslySetInnerHTML={{
          __html: headline
        }}/>
      <SVG name="SWave" color={wave_color}/>
      <p dangerouslySetInnerHTML={{
          __html: statement
        }} color={statement_color}/>
      <Button to="/flavors">{button_label}</Button>
    </div>
  </ContentStyled>)
}

const NotFoundPage = () => {
  const data = useStaticQuery(graphql `
    query {
      allWordpressPage(filter: { slug: { eq: "404-page" } }) {
        edges {
          node {
            content
            title
            acf {
              button_label
              top_line
              top_line_color
              headline
              headline_color
              statement
              statement_color
              wave_color
              background_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node
  const {title, content, acf} = fields
  return (<Layout>
    <SEO title="404 Page Not Found"/>
    <Content title={title} acf={acf} content={content}/>
    <ButtonColorNav/>
  </Layout>)
}

export default NotFoundPage
